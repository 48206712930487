import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["prefecture", "output"];

  connect() {
    // console.log("Hello, Im prefecrure_controller.");
    if (!this.prefectureTarget.value) {
    } else {
      const path = "/prefecture/" + this.prefectureTarget.value + "/municipalities.json";
      callApi(path, this.outputTarget, true);
    }
  }

  changePrefecture() {
    const path = "/prefecture/" + this.prefectureTarget.value + "/municipalities.json";
    callApi(path, this.outputTarget, false);
  }

  changeSearchPrefecture() {
    const path = "/prefecture/" + this.prefectureTarget.value + "/municipalities.json";
    callApi(path, this.outputTarget, true);
  }
}

async function callApi(path, e, blankRequired) {
  try {
    const res = await fetch(path);
    const municipalities = await res.json();
    while (e.firstChild) {
      e.removeChild(e.firstChild);
    }

    if (blankRequired) {
      e.insertAdjacentHTML("afterbegin", `<option value=''>全てのエリア</option>`);
    }
    // 事業所の存在チェック
    if (municipalities.length) {
      municipalities.forEach(function (value) {
        e.insertAdjacentHTML("afterbegin", `<option value='${value.id}'>${value.name}</option>`);
      });
    } else {
      e.insertAdjacentHTML("afterbegin", `<option value>市町村が存在しません</option>`);
    }
  } catch (err) {
    console.error(err);
  }
}
