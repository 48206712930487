import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["userPolicy", "submitButton"];

  connect() {
    // console.log("Hello, Im check_controller.");
  }

  changeCheckbox() {
    if (this.userPolicyTarget.checked == true) {
      this.submitButtonTarget.disabled = false;
    } else {
      this.submitButtonTarget.disabled = true;
    }
  }
}
